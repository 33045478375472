import styled, {createGlobalStyle} from "styled-components";


const globalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Source Sans Pro',sans-serif;
        -moz-user-select:none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        :root {
            --primary: #e95b0d;
            --secondary: #0583d8;
            --paragraph: #333333;
            --background: #F8F8F8;
            --dark-grey: #333;
            --light-gray: #787878;
            --dark-blue: #121A24;
        }
    }
`;

export default globalStyle;