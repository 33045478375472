import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import Home from "./Home";
import Navbar from "./Navbar";


function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Navbar />
      <Routes>
      <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
