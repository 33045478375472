import React from "react";
import styled from "styled-components";

const NavStyles = styled.div`
  background-color: #f8f8f8;
  padding: 1rem 0;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: .8rem 0;
  }

  @media screen and (max-width: 480px) {
    padding: .5rem 0;
  }

  img {
    height: 2rem;
    width: auto;

    @media screen and (max-width: 991px) {
        height: 1.7rem;
    }

    @media screen and (max-width: 767px) {
        height: 1.5rem;
    }

    @media screen and (max-width: 480px) {
        height: 1.3rem;
    }
  }
`;

const Navbar = () => {
  return (
    <NavStyles>
      <a href="https://www.artographertacts.com/">
        <img src="./images/logo_arto.png" alt="" />
      </a>
    </NavStyles>
  );
};

export default Navbar;
