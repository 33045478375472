import React from "react";
import styled from "styled-components";
import { GoAlertFill } from "react-icons/go";

const HomeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .alert-icon {
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }

    .icon {
      font-size: 10rem;
      color: #ff0000;

      @media screen and (max-width: 991px) {
        font-size: 8rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 7rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 5rem;
      }
    }
  }

  h3 {
    font-weight: 600;

      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
        margin-bottom: 0.3rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 1.1rem;
        margin-bottom: .1rem;
      }
  }

  p {

    @media screen and (max-width: 480px) {
        font-size: .9rem;
      }

    @media screen and (max-width: 480px) {
        font-size: .8rem;
      }
  }
`;

const Home = () => {
  return (
    <HomeStyles>
      <div className="alert-icon">
        <GoAlertFill className="icon" />
      </div>
      <h3>Sorry! The hosting plan has expired.</h3>
      <p>Please contact the administrator Now!</p>
    </HomeStyles>
  );
};

export default Home;
